.mt-Minus100 {
  margin-top: -100px;
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

@font-face {
  font-family: "Orbitron";
  src: url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");
}

@font-face {
  font-family: "Ceoruse";
  src: url("./fonts/ceoruse.otf");
}

// USAGE
html,
body {
  font-family: "Orbitron", sans-serif !important;
  background: #000000;
  color: #ffffff;
  text-transform: lowercase;
}
html {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
body {
  height: 100%;
}

.aisColors {
  font-family: "Ceoruse", sans-serif !important;
  background: #9ae0f6;
  background: -webkit-linear-gradient(
    -38deg,
    #9ae0f6 0%,
    #2ebcee 16%,
    #d6edff 70%,
    #5fcaf6 100%
  );
  background: -moz-linear-gradient(
    -38deg,
    #9ae0f6 0%,
    #2ebcee 16%,
    #d6edff 70%,
    #5fcaf6 100%
  );
  background: linear-gradient(
    -38deg,
    #9ae0f6 0%,
    #2ebcee 16%,
    #d6edff 70%,
    #5fcaf6 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.h4 .aisColors {
  font-size: 1.85rem;
}
p.textSize {
  font-size: 1rem;
}
h1 {
  font-size: 2.5rem;
}
table td,
table th {
  color: #ffffff;
}

.roadmapTitleColor {
  color: #2ebcee;
  text-align: center;
}

.roadmapBodyTextColor {
  color: #5fcaf6;
}
.borderCardRoadmap {
  border: #9ae0f6 solid 1px;
}

.card-body {
  height: 50vh;
}
.card.rounded-0.mt-Minus100 {
  border: none;
}
.card.img {
  border-bottom-right-radius: none;
  border-bottom-left-radius: none;
}

i {
  font-size: 1.5rem;
}

.aisLinkColor {
  text-decoration: none;
  color: #ffffff;
  transition: "1s ease" !important;
}

.aisLinkColor:hover {
  color: #9ae0f6 !important;
}

.bgThinking {
  background: url("./images/connected.png") center center no-repeat cover;
}
.marginAuto {
  margin: auto 0 !important;
}

.active-scroll-spy {
  background-color: yellowgreen;
  border-radius: 15px;
}
.shaiInput {
  background: transparent;
  border: none;
  color: #ffffff;
  padding: 0px;
}
.shaiInput:hover,
.shaiInput:focus,
.shaiInput:active {
  background: transparent;
  border: none;
  color: #ffffff;
  padding: 0px;
}
i.bi.bi-clipboard {
  font-size: 0.75rem;
  vertical-align: text-bottom;
}

.toastrPosition {
  position: absolute;
  bottom: 0;
  right: 0;
}

hr {
  border: 0;
  height: 3px;
  background-image: linear-gradient(
    to right,
    rgba(154, 224, 246, 0),
    rgba(46, 188, 238),
    rgba(214, 237, 255),
    rgba(95, 202, 246, 0)
  );
}

.list-group {
}
.list-group-item.active {
  background: transparent;
}
.list-group-item {
  background-color: transparent;
  color: #ffffff !important;
}
.list-group-item .list-group-item-action {
  border: none;
}

.list-group-item.active {
  border: none;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  text-decoration: none;
  background-color: transparent;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: 0px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 1;
  transition: opacity 0.6s ease;
}

div.carousel-indicators > button:nth-child(1),
div.carousel-indicators > button:nth-child(2) {
  visibility: hidden;
}
div.carousel-indicators > button:nth-child(1):after {
  content: "2023 ";
  visibility: visible;
  color: #ffffff;
}
div.carousel-indicators > button:nth-child(2):after {
  content: " 2024";
  visibility: visible;
  color: #ffffff;
}

div.carousel-indicators > button:nth-child(1).active:after {
  color: #5fcaf6;
  opacity: 1;
  transition: opacity 0.6s ease;
}
div.carousel-indicators > button:nth-child(2).active:after {
  color: #5fcaf6;
  opacity: 1;
}

#overlayWalletBox.card-img-overlay {
  background: rgba(0, 0, 0, 0.75);
  width: 45%;
  position: absolute;
  top: auto;
  left: auto;
  bottom: 12%;
  height: 55%;
  right: 0;
  border-radius: 10px;
}

.timeBox {
  background: rgba(255, 255, 255, 0.75);
  color: #000000;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  width: 90px;
  height: 60px;
  margin: auto;
}
.timeHeader {
  margin: auto 0;
  padding: 17px 0;
}

.card-title.h1 {
  text-align: center;
  padding: 20px 0px;
  border-radius: 10px 10px 0 0;
  border-left: 4px;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.counter {
  color: #000000;
  font-size: 18px;
  text-align: center;
}
.counter .col-md-2 {
  background: rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  width: 15%;
  padding: 7px 0;
  margin-right: 10px;
}

.walletBoxText {
  color: #ffffff;
}

#whitepaperModal {
  position: absolute;
  z-index: 999;
  bottom: 10%;
  left: 5%;
}
.modal-content {
  background: #000000;
}

ol {
  counter-reset: item;
  font-size: 1.45rem;
}
ol li {
  display: block;
}
ol li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

ol.leaders li span {
  float: right;
  padding: 0 0 0 0.4em;
  margin: 0;
}

ol.leaders li:after {
  content: "";
  display: block;
  overflow: hidden;
  height: 1em;
  border-bottom: 1px dotted;
}

.btn-close {
  background-color: #ffffff;
}

.toc table {
  width: 100%;
  margin: 0 auto;
  margin-top: 1ex;
  display: block;
}
.toc table tbody {
  display: block;
}
.toc table tr {
  display: flex;
}
.toc table tr td {
  width: auto !important;
}
.toc table tr td:nth-child(2) {
  order: 2;
  white-space: nowrap;
}
.toc table tr td:last-child {
  order: 3;
  white-space: nowrap;
}
.toc table.indent tr td:last-child {
  order: 3;
  white-space: nowrap;
  margin-right: 25px;
}
.toc table tr:after {
  flex-grow: 1;
  order: 2;
  content: ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . ";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.indent {
  margin-left: 25px !important;
}

.scroll-to-top {
  position: fixed;
  bottom: 40px;
  font-size: 5rem;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  right: 2%;
  background: transparent;
  border-radius: 50px;
  padding: 0px;
  border: none;
  opacity: 0.7;
}
.scroll-to-top:hover {
  background: transparent;
  color: #ffffff;
}

.timeline {
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 5rem;
  margin: 0 auto 1rem auto;
  overflow: hidden;
}
.timeline:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -2px;
  border-right: 2px dashed #4b546f;
  height: 100%;
  display: block;
}

.timeline-row {
  padding-left: 50%;
  position: relative;
  margin-bottom: 30px;
}
.timeline-row .timeline-time {
  position: absolute;
  right: 50%;
  top: 15px;
  text-align: right;
  margin-right: 20px;
  color: #bcd0f7;
  font-size: 1.5rem;
}
.timeline-row .timeline-time small {
  display: block;
  font-size: 0.8rem;
}
.timeline-row .timeline-content {
  position: relative;
  padding: 20px 30px;
  background: #1a233a;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.timeline-row .timeline-content:after {
  content: "";
  position: absolute;
  top: 20px;
  height: 16px;
  width: 16px;
  background: #1a233a;
}
.timeline-row .timeline-content:before {
  content: "";
  position: absolute;
  top: 20px;
  right: -49px;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  z-index: 10;
  background: #272e48;
  border: 2px dashed #4b546f;
}
.timeline-row .timeline-content h4 {
  margin: 0 0 20px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 150%;
  text-align: left;
}
.timeline-row .timeline-content p {
  margin-bottom: 30px;
  line-height: 150%;
}
.timeline-row .timeline-content i {
  font-size: 1.2rem;
  line-height: 100%;
  padding: 15px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  background: #272e48;
  margin-bottom: 10px;
  display: inline-block;
}
.timeline-row .timeline-content .thumbs {
  margin-bottom: 20px;
  display: flex;
}
.timeline-row .timeline-content .thumbs img {
  margin: 5px;
  max-width: 60px;
}
.timeline-row .timeline-content .badge {
  color: #ffffff;
  background: linear-gradient(120deg, #00b5fd 0%, #0047b1 100%);
  margin: 0 5px !important;
  padding: 7px 15px;
}
.timeline-row:nth-child(even) .timeline-content {
  margin-left: 40px;
  text-align: left;
}
.timeline-row:nth-child(even) .timeline-content:after {
  left: -8px;
  right: initial;
  border-bottom: 0;
  border-left: 0;
  transform: rotate(-135deg);
}
.timeline-row:nth-child(even) .timeline-content:before {
  left: -52px;
  right: initial;
}
.timeline-row:nth-child(odd) {
  padding-left: 0;
  padding-right: 50%;
}
.timeline-row:nth-child(odd) .timeline-time {
  right: auto;
  left: 50%;
  text-align: left;
  margin-right: 0;
  margin-left: 20px;
}
.timeline-row:nth-child(odd) .timeline-content {
  margin-right: 40px;
}
.timeline-row:nth-child(odd) .timeline-content:after {
  right: -8px;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
}

.whitepaperIconSize {
  font-size: 5rem;
}

.hLines {
  overflow: hidden;
  text-align: center;
}

.hLines:before,
.hLines:after {
  background-color: #ffffff;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.hLines:before {
  right: 0.5em;
  margin-left: -50%;
}

.hLines:after {
  left: 0.5em;
  margin-right: -50%;
}

.inputIcon {
  width: 30px;
}
small.text-body-secondary {
  color: #ffffff !important;
}
.card-text {
  color: #ffffff;
}

.shibainuButton {
  color: #000000;
  background: #9ae0f6;
  border-color: #9ae0f6;
  background: linear-gradient(
    -38deg,
    #9ae0f6 0%,
    #2ebcee 16%,
    #d6edff 70%,
    #5fcaf6 100%
  );
}

.shibainuButton i {
  vertical-align: inherit;
}

.shibainuButton:hover,
.shibainuButton:focus,
.shibainuButton:active,
.shibainuButton.active,
.open .dropdown-toggle.shibainuButton {
  color: #000000;
  background-color: #2ebcee;
  border-color: #9ae0f6;
}

.shibainuButton:active,
.shibainuButton.active,
.open .dropdown-toggle.shibainuButton {
  background-image: none;
}

.shibainuButton.disabled,
.shibainuButton[disabled],
fieldset[disabled] .shibainuButton,
.shibainuButton.disabled:hover,
.shibainuButton[disabled]:hover,
fieldset[disabled] .shibainuButton:hover,
.shibainuButton.disabled:focus,
.shibainuButton[disabled]:focus,
fieldset[disabled] .shibainuButton:focus,
.shibainuButton.disabled:active,
.shibainuButton[disabled]:active,
fieldset[disabled] .shibainuButton:active,
.shibainuButton.disabled.active,
.shibainuButton[disabled].active,
fieldset[disabled] .shibainuButton.active {
  background-color: #5fcaf6;
  border-color: #9ae0f6;
}

.shibainuButton .badge {
  color: #5fcaf6;
  background-color: #ffffff;
}

#WhitePaperRoadmap > .vh-300.col > h1.h4.text-uppercase.mt-3 {
  display: none;
}

.aiscubesAccordionHeight {
  height: auto !important;
  padding: 0;
}

.accordion-body {
  color: #ffffff;
  background-color: #000000;
}
.accordion-button {
  background-color: #dcf3ff;
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #a2d2df;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #257ca3;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #257ca3;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(37, 124, 163, 0.25);
}
.card-body {
  background-color: #000000;
}
.card-title.h5 {
  color: #ffffff;
}
.aiscubesAccordionHeight .card-title.h5 {
  padding: 10px 0px 5px 10px;
}
.accordion-body i {
  vertical-align: middle;
}
.timeline-row .timeline-content:before {
  background-color: #ff3131;
  border: none;
}
#WhitePaperRoadmap
  > div
  > div
  > div:nth-child(1)
  > div.timeline-content.text-end:before {
  background-color: #16f529;
}

.smallIcon {
  font-size: 0.75rem;
}
.biggerIcons {
  font-size: 2.5rem;
}
.whitepaperFooter #whitepaperModal {
  position: relative !important;
  left: 0;
  bottom: 0;
  background: transparent;
  color: #ffffff;
  border: 0;
  padding: 0;
  margin: 0;
}
.whitepaperFooter #whitepaperModal i,
.whitepaperFooter ul {
  display: none;
}
.whitepaperFooter #whitepaperModal {
  text-decoration: none;
  color: #ffffff;
  transition: "1s ease" !important;
}

.whitepaperFooter #whitepaperModal:hover {
  color: #9ae0f6 !important;
}

/*MEDIA QUERIES*/

@media only screen and (max-width: 768px) {
  .p-4,
  .p-5 {
    padding: 0.5rem !important;
  }
  #ShaiProject > div > div > div:nth-child(1) > div:nth-child(1) {
    order: 2;
  }
  .counter .col-md-2 {
    width: 14%;
  }
  .whitepaperFooter #whitepaperModal {
    position: relative !important;
    left: 0;
    bottom: 0;
    background: transparent;
    color: #ffffff;
    border: 0;
    padding: 0;
    margin: 0;
  }
}
@media only screen and (max-width: 992px) {
  .marginAuto {
    margin: 40px 0 !important;
  }
  #overlayWalletBox.card-img-overlay {
    width: 100%;
    position: relative;
    border-radius: 0px;
  }
  ul nav.navbar {
    background-color: rgba(0, 0, 0, 1) !important;
  }

  .card img.card-img {
    margin-top: 135px;
  }

  #whitepaperModal {
    position: absolute;
    z-index: 999;
    top: 10%;
    right: 5%;
    bottom: auto;
    left: auto;
  }

  .timeline {
    padding: 15px;
  }
  .timeline:after {
    border: 0;
  }
  .timeline .timeline-row:nth-child(odd) {
    padding: 0;
  }
  .timeline .timeline-row:nth-child(odd) .timeline-time {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 0 10px 0;
  }
  .timeline .timeline-row:nth-child(odd) .timeline-content {
    margin: 0;
  }
  .timeline .timeline-row:nth-child(odd) .timeline-content:before {
    display: none;
  }
  .timeline .timeline-row:nth-child(odd) .timeline-content:after {
    display: none;
  }
  .timeline .timeline-row:nth-child(even) {
    padding: 0;
  }
  .timeline .timeline-row:nth-child(even) .timeline-time {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 0 10px 0;
    text-align: left;
  }
  .timeline .timeline-row:nth-child(even) .timeline-content {
    margin: 0;
  }
  .timeline .timeline-row:nth-child(even) .timeline-content:before {
    display: none;
  }
  .timeline .timeline-row:nth-child(even) .timeline-content:after {
    display: none;
  }
}
